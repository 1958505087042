<template>
    <localization :language="'ru-RU'">
        <intl :locale="'ru'">
            <div class="request-management">
                <CRow class="table-actions-row">
                    <v-col sm="12" cols="12" lg="4" class="pl-0">
                        <breadcrumbs :items="crumbs" />
                    </v-col>
                    <v-col
                        sm="12"
                        md="12"
                        lg="8"
                        class="action-btns d-flex justify-end align-center flex-column flex-md-row"
                    ></v-col>
                </CRow>
                <Grid
                    ref="grid"
                    class="request"
                    :resizable="true"
                    :sortable="true"
                    :filterable="true"
                    :pageable="gridPageable"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    :detail="detailTemplate"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td
                            :class="
                                dateOrder(dataItem.created_at, dataItem.status)
                            "
                        >
                            <div class="actionsColumn">
                                <CButton
                                    @click="
                                        currentItem = dataItem;
                                        showEntityModal = true;
                                    "
                                    class="actions-btn btn-square btn-info"
                                >
                                    <i class="la la-pencil"></i>
                                    Изменить статус
                                </CButton>
                            </div>
                        </td>
                    </template>
                    <template v-slot:number="{ props: { dataItem } }">
                        <td
                            :class="
                                dateOrder(dataItem.created_at, dataItem.status)
                            "
                        >
                            <span>
                                {{ dataItem.number }}
                            </span>
                        </td>
                    </template>
                    <template v-slot:have_questions_filter>
                        <boolean-filter
                            @change="
                                selectFilter(
                                    'have_questions',
                                    $event.value.value
                                )
                            "
                        />
                    </template>
                    <template v-slot:have_lunch_filter>
                        <boolean-filter
                            @change="
                                selectFilter('have_lunch', $event.value.value)
                            "
                        />
                    </template>
                    <template v-slot:file_exist_filter>
                        <boolean-filter
                            @change="
                                selectFilter('file_exist', $event.value.value)
                            "
                        />
                    </template>
                    <template v-slot:have_questions="{ props: { dataItem } }">
                        <td
                            :class="
                                dateOrder(dataItem.created_at, dataItem.status)
                            "
                        >
                            <span
                                class="m-badge m-badge--wide m-badge--wide"
                                :class="{
                                    'm-badge--success': dataItem.have_questions,
                                    'm-badge--danger': !dataItem.have_questions,
                                }"
                            >
                                {{ dataItem.have_questions ? 'ДА' : 'НЕТ' }}
                            </span>
                        </td>
                    </template>
                    <template v-slot:have_lunch="{ props: { dataItem } }">
                        <td
                            :class="
                                dateOrder(dataItem.created_at, dataItem.status)
                            "
                        >
                            <span
                                class="m-badge m-badge--wide m-badge--wide"
                                :class="{
                                    'm-badge--success': dataItem.have_lunch,
                                    'm-badge--danger': !dataItem.have_lunch,
                                }"
                            >
                                {{ dataItem.have_lunch ? 'ДА' : 'НЕТ' }}
                            </span>
                        </td>
                    </template>
                    <template v-slot:file_exist="{ props: { dataItem } }">
                        <td
                            :class="
                                dateOrder(dataItem.created_at, dataItem.status)
                            "
                        >
                            <CButton
                                v-if="dataItem.file_exist"
                                @click="downloadFile(dataItem.id)"
                                class="actions-btn btn-square btn-info"
                            >
                                <i class="la la-arrow-down"></i>
                                Скачать
                            </CButton>
                            <span
                                v-else
                                class="m-badge m-badge--wide m-badge--danger"
                            >
                                Файл не загружен
                            </span>
                        </td>
                    </template>
                    <template v-slot:basic_cost="{ props: { dataItem } }">
                        <td>
                            {{ dataItem.basic_cost | fractionSizeSpace }}
                        </td>
                    </template>
                    <template v-slot:full_cost="{ props: { dataItem } }">
                        <td>
                            {{ dataItem.full_cost | fractionSizeSpace }}
                        </td>
                    </template>
                    <template v-slot:status_filter>
                        <boolean-filter
                            @change="selectFilter('status', $event.value.value)"
                        />
                    </template>
                    <template v-slot:status="{ props: { dataItem } }">
                        <td
                            :class="
                                dateOrder(dataItem.created_at, dataItem.status)
                            "
                        >
                            {{ dataItem.status_name }}
                        </td>
                    </template>
                    <template v-slot:detail="{ props: { dataItem } }">
                        <div>
                            <v-tabs v-model="tab">
                                <v-tab
                                    key="additionalServices"
                                    href="#additionalServices"
                                    >Дополнительная информация
                                </v-tab>
                                <v-tab key="client" href="#client">Дилер</v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                                <v-tab-item
                                    key="additionalServices"
                                    id="additionalServices"
                                >
                                    <div class="py-5 px-5">
                                        <table
                                            class="table table-striped m-table"
                                        >
                                            <tr>
                                                <td style="width: 300px">
                                                    Дата, время прибытия на ТПП
                                                </td>
                                                <td>
                                                    <p>
                                                        {{ moment(dataItem.created_at).format('DD.MM.YYYY') }}
                                                        {{ dataItem.time }}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Нужна ли сессия
                                                    вопросы/ответы после
                                                    экскурсии?
                                                </td>
                                                <td>
                                                    <p>
                                                        {{
                                                            dataItem.have_questions ===
                                                            1
                                                                ? 'Да'
                                                                : 'Нет'
                                                        }}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Нужен ли обед на территории
                                                    ТПП?
                                                </td>
                                                <td>
                                                    <p>
                                                        {{
                                                            dataItem.have_lunch ===
                                                            1
                                                                ? 'Да'
                                                                : 'Нет'
                                                        }}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Гос. номер ТС для въезда на
                                                    ТПП
                                                </td>
                                                <td>
                                                    <p
                                                        v-html="
                                                            dataItem.state_number
                                                        "
                                                    ></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Гостиница проживания
                                                    участников
                                                </td>
                                                <td>
                                                    <p
                                                        v-html="dataItem.hotel"
                                                    ></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Транспорт прибытия
                                                    участников (авто, ж/д, авиа)
                                                </td>
                                                <td>
                                                    <p
                                                        v-html="
                                                            dataItem.transport
                                                        "
                                                    ></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Место проведения делового
                                                    ужина
                                                </td>
                                                <td>
                                                    <p
                                                        v-html="
                                                            dataItem.dinner_place
                                                        "
                                                    ></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Планируется ли экскурсия по
                                                    городу?
                                                </td>
                                                <td>
                                                    <p>
                                                        {{
                                                            dataItem.have_city_excursion ===
                                                            1
                                                                ? 'Да'
                                                                : 'Нет'
                                                        }}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Планируется ли фотосъемка?
                                                </td>
                                                <td>
                                                    <p>
                                                        {{
                                                            dataItem.have_photo ===
                                                            1
                                                                ? 'Да'
                                                                : 'Нет'
                                                        }}
                                                    </p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </v-tab-item>
                                <v-tab-item key="client" id="client">
                                    <div class="py-5 px-5">
                                        <table
                                            class="table table-striped m-table"
                                        >
                                            <tr>
                                                <td style="width: 300px">
                                                    Наименование дилера:
                                                </td>
                                                <td>
                                                    <p
                                                        v-html="
                                                            dataItem.dealer_name
                                                        "
                                                    ></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>КССС дилера:</td>
                                                <td>
                                                    <p
                                                        v-html="
                                                            dataItem.unique_id
                                                        "
                                                    ></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>ФИО контактного лица:</td>
                                                <td>
                                                    <p
                                                        v-html="
                                                            dataItem.contact_person_name
                                                        "
                                                    ></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Должность контактного лица:
                                                </td>
                                                <td>
                                                    <p
                                                        v-html="
                                                            dataItem.contact_person_position
                                                        "
                                                    ></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Телефон контактного лица:
                                                </td>
                                                <td>
                                                    <p
                                                        v-html="
                                                            dataItem.contact_person_phone
                                                        "
                                                    ></p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </v-tab-item>
                            </v-tabs-items>
                        </div>
                    </template>
                    <template v-slot:date_filter="{ methods, props }">
                        <div class="d-flex align-end custom">
                            <datepicker
                                :default-show="defaultShow"
                                :value="value"
                                @change="dateChangeHandler"
                            />
                            <button
                                @click="clearDates"
                                title="Clear"
                                type="button"
                                class="k-button k-button-icon k-clear-button-visible"
                            >
                                <span class="k-icon k-i-filter-clear"></span>
                            </button>
                        </div>
                    </template>
                    <template v-slot:created_at="{ props: { dataItem } }">
                        <td
                            :class="
                                dateOrder(dataItem.created_at, dataItem.status)
                            "
                        >
                            <span>
                                {{ moment(dataItem.created_at).format('DD.MM.YYYY') }}
                            </span>
                        </td>
                    </template>
                    <template v-slot:dealer_name="{ props: { dataItem } }">
                        <td
                            :class="
                                dateOrder(dataItem.created_at, dataItem.status)
                            "
                        >
                            <span>
                                {{ dataItem.dealer_name }}
                            </span>
                        </td>
                    </template>
                    <template v-slot:unique_id="{ props: { dataItem } }">
                        <td
                            :class="
                                dateOrder(dataItem.created_at, dataItem.status)
                            "
                        >
                            <span>
                                {{ dataItem.unique_id }}
                            </span>
                        </td>
                    </template>
                    <template v-slot:visit_at="{ props: { dataItem } }">
                        <td
                            :class="
                                dateOrder(dataItem.created_at, dataItem.status)
                            "
                        >
                            <span>
                                {{ moment(dataItem.created_at).format('DD.MM.YYYY') }}
                            </span>
                        </td>
                    </template>
                    <template v-slot:tpp_name="{ props: { dataItem } }">
                        <td
                            :class="
                                dateOrder(dataItem.created_at, dataItem.status)
                            "
                        >
                            <span>
                                {{ dataItem.tpp_name }}
                            </span>
                        </td>
                    </template>
                    <template v-slot:user_count="{ props: { dataItem } }">
                        <td
                            :class="
                                dateOrder(dataItem.created_at, dataItem.status)
                            "
                        >
                            <span>
                                {{ dataItem.user_count }}
                            </span>
                        </td>
                    </template>
                    <template
                        v-slot:curator_from_dealer_name="{
                            props: { dataItem },
                        }"
                    >
                        <td
                            :class="
                                dateOrder(dataItem.created_at, dataItem.status)
                            "
                        >
                            <span>
                                {{ dataItem.curator_from_dealer_name }}
                            </span>
                        </td>
                    </template>
                    <template
                        v-slot:curator_from_dealer_phone_number="{
                            props: { dataItem },
                        }"
                    >
                        <td
                            :class="
                                dateOrder(dataItem.created_at, dataItem.status)
                            "
                        >
                            <span>
                                {{ dataItem.curator_from_dealer_phone_number }}
                            </span>
                        </td>
                    </template>
                    <template
                        v-slot:curator_from_dealer_email="{
                            props: { dataItem },
                        }"
                    >
                        <td
                            :class="
                                dateOrder(dataItem.created_at, dataItem.status)
                            "
                        >
                            <span>
                                {{ dataItem.curator_from_dealer_email }}
                            </span>
                        </td>
                    </template>
                </Grid>
                <change-status-modal
                    order-type="dealer"
                    v-if="showEntityModal"
                    :current-item="currentItem"
                    :show-dialog="showEntityModal"
                    @click:outside="showEntityModal = false"
                    @stored="entityStored($event)"
                    @close="showEntityModal = false"
                ></change-status-modal>
            </div>
        </intl>
    </localization>
</template>

<script>
    import api from '@/api';
    import { mapActions, mapGetters } from 'vuex';
    import moment from 'moment'
    import { Grid } from '@progress/kendo-vue-grid';
    import ChangeVisitStatusModal from '@/views/content/agent-user/modals/ChangeVisitStatusModal';
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl';
    import '@/views/grid_elements/translations';
    import TableMixin from '@/views/grid_elements/mixins/TableMixin';
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin';
    import Breadcrumbs from '@/views/components/breadcrumbs';
    import BooleanFilter from '@/views/components/BooleanFilter';
    import { DatePicker } from '@progress/kendo-vue-dateinputs';

    export default {
        name: 'request-management',
        components: {
            localization: LocalizationProvider,
            intl: IntlProvider,
            Grid: Grid,
            breadcrumbs: Breadcrumbs,
            BooleanFilter,
            datepicker: DatePicker,
            'change-status-modal': ChangeVisitStatusModal,
        },
        mixins: [TableMixin, ResizableTableMixin],
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                total: 7,
                tab: '',
                items: null,
                gridPageable: { pageSizes: true },
                detailTemplate: 'detail',
                expandedItems: [],
                defaultShow: false,
                value: null,
                moment,
                columns: [
                    {
                        field: 'id',
                        title: 'Действия',
                        cell: 'actions',
                        minWidth: 150,
                        width: '150px',
                        filterable: false,
                        sortable: false,
                    },
                    {
                        field: 'number',
                        title: 'Номер заказа',
                        cell: 'number',
                        type: 'text',
                        filter: 'text',
                        minWidth: 160,
                        width: '160px',
                    },
                    {
                        field: 'created_at',
                        title: 'Дата создания заявки',
                        type: 'date',
                        cell: 'created_at',
                        filter: 'date',
                        format: '{0:d}',
                        minWidth: 250,
                        width: '250px',
                        filterCell: 'date_filter'
                    },
                    {
                        field: 'status',
                        title: 'Статус',
                        cell: 'status',
                        filterCell: 'status_filter',
                        minWidth: 160,
                        width: '160px',
                    },
                    {
                        field: 'dealer_name',
                        title: 'Дилер',
                        cell: 'dealer_name',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'unique_id',
                        title: 'КССС',
                        cell: 'unique_id',
                        type: 'text',
                        filter: 'text',
                        minWidth: 160,
                        width: '160px',
                    },
                    {
                        field: 'visit_at',
                        title: 'Дата поездки',
                        cell: 'visit_at',
                        type: 'date',
                        filter: 'date',
                        format: '{0:d}',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'tpp_name',
                        cell: 'tpp_name',
                        title: 'Объект',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'user_count',
                        cell: 'user_count',
                        title: 'Количество участников',
                        type: 'numeric',
                        filter: 'numeric',
                        minWidth: 200,
                        width: '200px',
                    },
                    {
                        field: 'have_questions',
                        title: 'Нужна ли сессия вопросы/ответы?',
                        type: 'boolean',
                        cell: 'have_questions',
                        filterCell: 'have_questions_filter',
                        filter: 'boolean',
                        minWidth: 300,
                        width: '300px',
                    },
                    {
                        field: 'have_lunch',
                        title: 'Нужен ли обед?',
                        type: 'boolean',
                        cell: 'have_lunch',
                        filterCell: 'have_lunch_filter',
                        filter: 'boolean',
                        minWidth: 300,
                        width: '300px',
                    },
                    {
                        field: 'curator_from_dealer_name',
                        title: 'ФИО',
                        cell: 'curator_from_dealer_name',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'curator_from_dealer_phone_number',
                        title: 'Номер телефона',
                        cell: 'curator_from_dealer_phone_number',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'curator_from_dealer_email',
                        cell: 'curator_from_dealer_email',
                        title: 'E-mail',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    // {
                    //     field: 'basic_cost',
                    //     cell: 'basic_cost',
                    //     title: 'Базовая стоимость',
                    //     type: 'numeric',
                    //     filter: 'numeric',
                    //     minWidth: 200,
                    //     width: '200px',
                    // },
                    // {
                    //     field: 'full_cost',
                    //     cell: 'full_cost',
                    //     title: 'Общая сумма заказа',
                    //     type: 'numeric',
                    //     filter: 'numeric',
                    //     minWidth: 200,
                    //     width: '200px',
                    // },
                    {
                        field: 'file_exist',
                        title: 'Список участников',
                        type: 'boolean',
                        cell: 'file_exist',
                        filterCell: 'file_exist_filter',
                        filter: 'boolean',
                        minWidth: 300,
                        width: '300px',
                    },
                ],
            };
        },
        mounted() {
            if (this.isCurrentUserAdministrator) {
                this.fetchAgencyListShort();
            }

            this.fetchDealerListShort();
            this.getData();
        },
        computed: {
            ...mapGetters('dealer', ['dealersListShort']),
            dealerFilter() {
                return [
                    {
                        id: null,
                        name: 'Все',
                    },
                    ...this.dealersListShort,
                ];
            },
        },
        methods: {
            getNumberOfDays(start, end) {
                const dateStart = new Date(start);
                const dateEnd = new Date(end);
                const oneDay = 1000 * 60 * 60 * 24;
                const diffInTime = dateEnd.getTime() - dateStart.getTime();
                const diffInDays = Math.round(diffInTime / oneDay);
                return diffInDays;
            },
            dateOrder(date, status) {
                const currentDate = new Date();
                const orderDate = new Date(date);
                const dateSum = this.getNumberOfDays(orderDate, currentDate);
                if (dateSum > 5 && status === 1) {
                    return 'dateError';
                } else {
                    return '';
                }
            },
            dateChangeHandler({value}) {
                this.value = value
                this.removeFilter('date');
                this.setFilter('created_at','contains', this.value);
                this.updateTable(true);
            },
            clearDates() {
                this.value = null
                this.removeFilter('created_at')
                this.updateTable(true);
            },
            async downloadFile(id) {
                try {
                    const response = await api.agent_organizer.getVisitDocs(id);

                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', `Участники поездки.xlsx`);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                } catch (e) {
                    console.warn(e);
                }
            },
            fetchData: api.agent_organizer.getVisitOrdersManagement,
            ...mapActions('dealer', ['fetchDealerListShort']),
            selectDealer({ value }) {
                if (value.id) {
                    this.setFilter('dealer_id', 'eq', value.id);
                } else {
                    this.removeFilter('dealer_id');
                }

                this.updateTable(true);
            },
        },
    };
</script>

<style lang="sass" scoped>
    .details-table::v-deep
      tr
        td
          &:nth-child(1)
            width: 200px

    .order-items-table-header::v-deep
      tr
        th
          &:nth-child(1)
            width: 500px

    .order-items-table::v-deep
      tr
        td
          &:nth-child(1)
            width: 500px

    .client-table::v-deep
      tr
        td
          &:nth-child(1)
            width: 300px


    .history-table-header::v-deep
      tr
        th
          &:nth-child(1)
            width: 300px

    .history-table::v-deep
      tr
        td
          &:nth-child(1)
            width: 300px

    .actions-btn
      white-space: normal
      padding: 3px 10px !important

      &.btn::after
        content: none !important

    .order-item-actions
      margin-top: 3px
      margin-bottom: 13px

      .edit-order-btn
        font-size: 12px
        padding: 0.3rem 0.8rem

    .order-items-table
      .remove-order-item
        cursor: pointer
        color: #060a13
        font-size: 16px
        font-weight: bold
        margin-right: 8px

    .dateError
        color: #ff6358
        background: #ff635833
</style>

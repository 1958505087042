var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('localization',{attrs:{"language":'ru-RU'}},[_c('intl',{attrs:{"locale":'ru'}},[_c('div',{staticClass:"request-management"},[_c('CRow',{staticClass:"table-actions-row"},[_c('v-col',{staticClass:"pl-0",attrs:{"sm":"12","cols":"12","lg":"4"}},[_c('breadcrumbs',{attrs:{"items":_vm.crumbs}})],1),_c('v-col',{staticClass:"action-btns d-flex justify-end align-center flex-column flex-md-row",attrs:{"sm":"12","md":"12","lg":"8"}})],1),_c('Grid',{ref:"grid",staticClass:"request",attrs:{"resizable":true,"sortable":true,"filterable":true,"pageable":_vm.gridPageable,"filter":_vm.filter,"take":_vm.take,"skip":_vm.skip,"sort":_vm.sort,"page-size":_vm.pageSize,"total":_vm.total,"data-items":_vm.dataResult,"columns":_vm.columns,"expand-field":'expanded',"detail":_vm.detailTemplate},on:{"datastatechange":_vm.dataStateChange,"expandchange":_vm.expandChange,"sortchange":_vm.sortChange},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var dataItem = ref.props.dataItem;
return [_c('td',{class:_vm.dateOrder(dataItem.created_at, dataItem.status)},[_c('div',{staticClass:"actionsColumn"},[_c('CButton',{staticClass:"actions-btn btn-square btn-info",on:{"click":function($event){_vm.currentItem = dataItem;
                                    _vm.showEntityModal = true;}}},[_c('i',{staticClass:"la la-pencil"}),_vm._v(" Изменить статус ")])],1)])]}},{key:"number",fn:function(ref){
                                    var dataItem = ref.props.dataItem;
return [_c('td',{class:_vm.dateOrder(dataItem.created_at, dataItem.status)},[_c('span',[_vm._v(" "+_vm._s(dataItem.number)+" ")])])]}},{key:"have_questions_filter",fn:function(){return [_c('boolean-filter',{on:{"change":function($event){return _vm.selectFilter(
                                'have_questions',
                                $event.value.value
                            )}}})]},proxy:true},{key:"have_lunch_filter",fn:function(){return [_c('boolean-filter',{on:{"change":function($event){return _vm.selectFilter('have_lunch', $event.value.value)}}})]},proxy:true},{key:"file_exist_filter",fn:function(){return [_c('boolean-filter',{on:{"change":function($event){return _vm.selectFilter('file_exist', $event.value.value)}}})]},proxy:true},{key:"have_questions",fn:function(ref){
                            var dataItem = ref.props.dataItem;
return [_c('td',{class:_vm.dateOrder(dataItem.created_at, dataItem.status)},[_c('span',{staticClass:"m-badge m-badge--wide m-badge--wide",class:{
                                'm-badge--success': dataItem.have_questions,
                                'm-badge--danger': !dataItem.have_questions,
                            }},[_vm._v(" "+_vm._s(dataItem.have_questions ? 'ДА' : 'НЕТ')+" ")])])]}},{key:"have_lunch",fn:function(ref){
                            var dataItem = ref.props.dataItem;
return [_c('td',{class:_vm.dateOrder(dataItem.created_at, dataItem.status)},[_c('span',{staticClass:"m-badge m-badge--wide m-badge--wide",class:{
                                'm-badge--success': dataItem.have_lunch,
                                'm-badge--danger': !dataItem.have_lunch,
                            }},[_vm._v(" "+_vm._s(dataItem.have_lunch ? 'ДА' : 'НЕТ')+" ")])])]}},{key:"file_exist",fn:function(ref){
                            var dataItem = ref.props.dataItem;
return [_c('td',{class:_vm.dateOrder(dataItem.created_at, dataItem.status)},[(dataItem.file_exist)?_c('CButton',{staticClass:"actions-btn btn-square btn-info",on:{"click":function($event){return _vm.downloadFile(dataItem.id)}}},[_c('i',{staticClass:"la la-arrow-down"}),_vm._v(" Скачать ")]):_c('span',{staticClass:"m-badge m-badge--wide m-badge--danger"},[_vm._v(" Файл не загружен ")])],1)]}},{key:"basic_cost",fn:function(ref){
                            var dataItem = ref.props.dataItem;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("fractionSizeSpace")(dataItem.basic_cost))+" ")])]}},{key:"full_cost",fn:function(ref){
                            var dataItem = ref.props.dataItem;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("fractionSizeSpace")(dataItem.full_cost))+" ")])]}},{key:"status_filter",fn:function(){return [_c('boolean-filter',{on:{"change":function($event){return _vm.selectFilter('status', $event.value.value)}}})]},proxy:true},{key:"status",fn:function(ref){
                            var dataItem = ref.props.dataItem;
return [_c('td',{class:_vm.dateOrder(dataItem.created_at, dataItem.status)},[_vm._v(" "+_vm._s(dataItem.status_name)+" ")])]}},{key:"detail",fn:function(ref){
                            var dataItem = ref.props.dataItem;
return [_c('div',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"additionalServices",attrs:{"href":"#additionalServices"}},[_vm._v("Дополнительная информация ")]),_c('v-tab',{key:"client",attrs:{"href":"#client"}},[_vm._v("Дилер")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"additionalServices",attrs:{"id":"additionalServices"}},[_c('div',{staticClass:"py-5 px-5"},[_c('table',{staticClass:"table table-striped m-table"},[_c('tr',[_c('td',{staticStyle:{"width":"300px"}},[_vm._v(" Дата, время прибытия на ТПП ")]),_c('td',[_c('p',[_vm._v(" "+_vm._s(_vm.moment(dataItem.created_at).format('DD.MM.YYYY'))+" "+_vm._s(dataItem.time)+" ")])])]),_c('tr',[_c('td',[_vm._v(" Нужна ли сессия вопросы/ответы после экскурсии? ")]),_c('td',[_c('p',[_vm._v(" "+_vm._s(dataItem.have_questions === 1 ? 'Да' : 'Нет')+" ")])])]),_c('tr',[_c('td',[_vm._v(" Нужен ли обед на территории ТПП? ")]),_c('td',[_c('p',[_vm._v(" "+_vm._s(dataItem.have_lunch === 1 ? 'Да' : 'Нет')+" ")])])]),_c('tr',[_c('td',[_vm._v(" Гос. номер ТС для въезда на ТПП ")]),_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(
                                                        dataItem.state_number
                                                    )}})])]),_c('tr',[_c('td',[_vm._v(" Гостиница проживания участников ")]),_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(dataItem.hotel)}})])]),_c('tr',[_c('td',[_vm._v(" Транспорт прибытия участников (авто, ж/д, авиа) ")]),_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(
                                                        dataItem.transport
                                                    )}})])]),_c('tr',[_c('td',[_vm._v(" Место проведения делового ужина ")]),_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(
                                                        dataItem.dinner_place
                                                    )}})])]),_c('tr',[_c('td',[_vm._v(" Планируется ли экскурсия по городу? ")]),_c('td',[_c('p',[_vm._v(" "+_vm._s(dataItem.have_city_excursion === 1 ? 'Да' : 'Нет')+" ")])])]),_c('tr',[_c('td',[_vm._v(" Планируется ли фотосъемка? ")]),_c('td',[_c('p',[_vm._v(" "+_vm._s(dataItem.have_photo === 1 ? 'Да' : 'Нет')+" ")])])])])])]),_c('v-tab-item',{key:"client",attrs:{"id":"client"}},[_c('div',{staticClass:"py-5 px-5"},[_c('table',{staticClass:"table table-striped m-table"},[_c('tr',[_c('td',{staticStyle:{"width":"300px"}},[_vm._v(" Наименование дилера: ")]),_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(
                                                        dataItem.dealer_name
                                                    )}})])]),_c('tr',[_c('td',[_vm._v("КССС дилера:")]),_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(
                                                        dataItem.unique_id
                                                    )}})])]),_c('tr',[_c('td',[_vm._v("ФИО контактного лица:")]),_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(
                                                        dataItem.contact_person_name
                                                    )}})])]),_c('tr',[_c('td',[_vm._v(" Должность контактного лица: ")]),_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(
                                                        dataItem.contact_person_position
                                                    )}})])]),_c('tr',[_c('td',[_vm._v(" Телефон контактного лица: ")]),_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(
                                                        dataItem.contact_person_phone
                                                    )}})])])])])])],1)],1)]}},{key:"date_filter",fn:function(ref){
                                                    var methods = ref.methods;
                                                    var props = ref.props;
return [_c('div',{staticClass:"d-flex align-end custom"},[_c('datepicker',{attrs:{"default-show":_vm.defaultShow,"value":_vm.value},on:{"change":_vm.dateChangeHandler}}),_c('button',{staticClass:"k-button k-button-icon k-clear-button-visible",attrs:{"title":"Clear","type":"button"},on:{"click":_vm.clearDates}},[_c('span',{staticClass:"k-icon k-i-filter-clear"})])],1)]}},{key:"created_at",fn:function(ref){
                                                    var dataItem = ref.props.dataItem;
return [_c('td',{class:_vm.dateOrder(dataItem.created_at, dataItem.status)},[_c('span',[_vm._v(" "+_vm._s(_vm.moment(dataItem.created_at).format('DD.MM.YYYY'))+" ")])])]}},{key:"dealer_name",fn:function(ref){
                                                    var dataItem = ref.props.dataItem;
return [_c('td',{class:_vm.dateOrder(dataItem.created_at, dataItem.status)},[_c('span',[_vm._v(" "+_vm._s(dataItem.dealer_name)+" ")])])]}},{key:"unique_id",fn:function(ref){
                                                    var dataItem = ref.props.dataItem;
return [_c('td',{class:_vm.dateOrder(dataItem.created_at, dataItem.status)},[_c('span',[_vm._v(" "+_vm._s(dataItem.unique_id)+" ")])])]}},{key:"visit_at",fn:function(ref){
                                                    var dataItem = ref.props.dataItem;
return [_c('td',{class:_vm.dateOrder(dataItem.created_at, dataItem.status)},[_c('span',[_vm._v(" "+_vm._s(_vm.moment(dataItem.created_at).format('DD.MM.YYYY'))+" ")])])]}},{key:"tpp_name",fn:function(ref){
                                                    var dataItem = ref.props.dataItem;
return [_c('td',{class:_vm.dateOrder(dataItem.created_at, dataItem.status)},[_c('span',[_vm._v(" "+_vm._s(dataItem.tpp_name)+" ")])])]}},{key:"user_count",fn:function(ref){
                                                    var dataItem = ref.props.dataItem;
return [_c('td',{class:_vm.dateOrder(dataItem.created_at, dataItem.status)},[_c('span',[_vm._v(" "+_vm._s(dataItem.user_count)+" ")])])]}},{key:"curator_from_dealer_name",fn:function(ref){
                    var dataItem = ref.props.dataItem;
return [_c('td',{class:_vm.dateOrder(dataItem.created_at, dataItem.status)},[_c('span',[_vm._v(" "+_vm._s(dataItem.curator_from_dealer_name)+" ")])])]}},{key:"curator_from_dealer_phone_number",fn:function(ref){
                    var dataItem = ref.props.dataItem;
return [_c('td',{class:_vm.dateOrder(dataItem.created_at, dataItem.status)},[_c('span',[_vm._v(" "+_vm._s(dataItem.curator_from_dealer_phone_number)+" ")])])]}},{key:"curator_from_dealer_email",fn:function(ref){
                    var dataItem = ref.props.dataItem;
return [_c('td',{class:_vm.dateOrder(dataItem.created_at, dataItem.status)},[_c('span',[_vm._v(" "+_vm._s(dataItem.curator_from_dealer_email)+" ")])])]}}])}),(_vm.showEntityModal)?_c('change-status-modal',{attrs:{"order-type":"dealer","current-item":_vm.currentItem,"show-dialog":_vm.showEntityModal},on:{"click:outside":function($event){_vm.showEntityModal = false},"stored":function($event){return _vm.entityStored($event)},"close":function($event){_vm.showEntityModal = false}}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
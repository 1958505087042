var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var untouched = ref.untouched;
var handleSubmit = ref.handleSubmit;
return [_c('v-dialog',{staticClass:"product-attachment-modal",attrs:{"width":"800","id":"product-image-picker-modal","value":_vm.showDialog}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('span',[_c('i',{staticClass:"fa fa-edit"}),_vm._v(" Подтверждение корректировки заказа № "+_vm._s(_vm.orderType == 'dealer' ? _vm.currentItem.number : _vm.currentItem.product_order_number))])]),_c('v-card-text',[_c('form',{staticClass:"m-form m-form--state"},[_c('div',[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|max:300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-textarea',{attrs:{"label":"Причина  *","error":invalid && validated},model:{value:(_vm.order_edit_reason),callback:function ($$v) {_vm.order_edit_reason=$$v},expression:"order_edit_reason"}})]}}],null,true)})],1)])]),_c('v-card-actions',[_c('v-spacer'),_c('CButton',{staticClass:"mr-3",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Отмена")]),_c('CButton',{attrs:{"disabled":invalid,"color":"success"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_c('span',[_vm._v("Подтвердить")])])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
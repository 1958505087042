<template>
  <v-dialog width="800" id="product-image-picker-modal" class="product-attachment-modal" :value="showDialog">
    <v-card>
      <v-card-title class="headline">
        <span><i class="fa fa-edit"></i> Изменение статуса заказа</span>
      </v-card-title>

      <v-card-text>
        <form class="m-form m-form--state">
          <div>
            <v-select v-model="status" :items="availableStatuses" item-value="key" item-text="name"
              :label="availableStatuses.length != 0 ? 'Изменить статус на *' : 'Уже установлен финальный статус, редактирование невозможно'"
              :disabled="availableStatuses.length != 0 ? false : true" hide-details="auto" required>
            </v-select>
          </div>
        </form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
        <CButton :disabled="!status" @click="submit" color="success"><i class="fa fa-arrow-right"></i> <span>Изменить
            статус</span></CButton>
      </v-card-actions>
    </v-card>
    <approve-status-modal order-type="dealer" v-if="showApproveModal" :current-item="currentItem"
      :show-dialog="showApproveModal" @modalClose='modalClose' @click:outside="showApproveModal = false"
      @stored="entityStored($event)" @close="showApproveModal = false"></approve-status-modal>
  </v-dialog>
</template>

<script>
import { serialize } from 'object-to-formdata';
import api from "@/api";
import { showMessage } from "@/lib/toasted";
import EntityModalMixin from "@/views/grid_elements/mixins/EntityModalMixin";
import { mapGetters } from "vuex";
import ApproveStatusModal from './ApproveVisitPositionDeletingModal.vue'

export default {
  name: "ChangeOrderStatusModal",
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    orderType: {
      type: String,
      default: 'dealer'
    }
  },
  components: {
    ApproveStatusModal
  },
  mixins: [ EntityModalMixin ],
  async mounted ()
  {
    await this.fetchStatuses();
  },
  data ()
  {
    return {
      status: null,
      statuses: [],
      showApproveModal: false
    }
  },
  methods: {

    modalClose ()
    {
      this.$emit( 'close' )
      this.$emit( 'stored' );
    },

    async fetchStatuses ()
    {
      try
      {
        const {
          data: { data: { statuses } }
        } = await api.orders.getAvailableStatusesToChangeVisit( this.currentItem.id );

        this.statuses = statuses;
      } catch ( e )
      {
        console.log( e );
      }
    },
    async submit ()
    {
      const method = api.orders.changeVisitOrderStatus
      if ( this.status === 4 )
      {
        this.showApproveModal = true
      } else
      {
        const { data: { status, message } } = await method( this.currentItem.id, this.status );

        showMessage( status, message );

        if ( status === 'ok' )
        {
          this.$emit( 'stored' );
        }
      }

    }
  },
  computed: {
    ...mapGetters( 'orders', [ 'getVisitOrderStatusNameByKey' ] ),
    availableStatuses ()
    {
      const that = this;

      return this.statuses.map( ( status ) => ( {
        key: status,
        name: that.getVisitOrderStatusNameByKey( status )
      } ) )
    }
  }
}
</script>

<style scoped>
</style>
